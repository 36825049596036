import { combineReducers } from 'redux';
import { isEmpty } from 'lodash-es';
import { PriceUpdateDirections } from 'constants/price-update-directions.enum';
import { Actions } from 'constants/actions.enum';
import { createReducer } from 'store/reducer-creator';
import { IAction } from 'types/actions';
import { ILineItem } from 'types/line-item';
import { ICarouselProduct } from 'types/product';
import {
  IExceededMaximumQuantitySkuInfo,
  IItemizedSkuInfo,
  IOutOfStockSkuReorderInfo,
  ISpecialOrderSku,
  IOutOfStockSkuInfo,
} from 'types/order-details';
import * as checkoutActions from 'store/checkout/actions';
import * as shoppingListsActions from 'store/shopping-lists/actions';
import { DEFAULT_PRICE_MODEL } from 'constants/price.enum';
import { impersonationStartActionConstants } from 'store/auth/actions';
import types from './action-types';
import * as actions from './actions';

export interface IOriginalToReplacementSkusMap {
  [key: string]: string;
}

export interface ICartPayload extends ICartInfo {
  lineItems: ILineItem[];
  discountDetails: IDiscountDetails;
}

export interface IReorderPayload {
  addedItemsCounter: number;
  cancelledSkuIds: string[];
  cartCounter: number;
  deactivatedSkus: IItemizedSkuInfo[];
  unavailableProprietarySkus: IItemizedSkuInfo[];
  originalToReplacementSkusMap?: IOriginalToReplacementSkusMap;
}

export interface ICartCoupon {
  discountDetails: IDiscountDetails;
  isPromotionalCodeValid: boolean;
}

export interface ICartState {
  info: ICartInfo;
  coupon: ICartCoupon;
  lineItems: ILineItem[];
  originalToReplacementSkusMap?: IOriginalToReplacementSkusMap;
  reorderOriginalToReplacementSkusMap?: IOriginalToReplacementSkusMap;
  deactivatedItemsSkus?: IItemizedSkuInfo[];
  loading: boolean;
  isProductBeingUpdated: boolean;
}

export interface IShoppingCartState {
  cartInfo: ICartState;
  productRecommendations: ICarouselProduct[];
}

export interface IPriceModel {
  amount: string;
  currencySymbol: string;
  segmentationAmount?: string;
}

export interface ILineItemPriceUpdate {
  sku: string;
  skuName: string;
  priceUpdateDirection: PriceUpdateDirections;
  priceModel: IPriceModel;
  previousPriceModel: IPriceModel;
}

export interface IDeactivatedItem {
  sku: string;
  skuName: string;
}

export interface IDiscounts {
  discountCode: string;
  discountedAmount: IPriceModel;
}

export interface IDiscountDetails {
  totalDiscountedAmount: IPriceModel;
  originalCartSubTotal: IPriceModel;
  discountError: string;
  discounts: IDiscounts[];
  discountErrorFromValidation?: string;
}

export interface ICartInfo {
  totalItemsCounter: number;
  totalQuantityCounter: number;
  cartCounter: number;
  subTotal: IPriceModel;
  isPresentDeactivatedItem: boolean;
  addedItemsCounter: number;
  cancelledSkuIds: string[];
  deactivatedSkus: IItemizedSkuInfo[];
  originalToReplacementSkusMap: IOriginalToReplacementSkusMap;
  deactivatedItemSkuAndSkuNames: IDeactivatedItem[];
  specialOrderSkus: ISpecialOrderSku[];
  unavailableProprietarySkus: IItemizedSkuInfo[];
  lineItemPriceUpdates: ILineItemPriceUpdate[];
  exceededMaxQuantitySkus: IExceededMaximumQuantitySkuInfo[];
  shortSupplyItems: IExceededMaximumQuantitySkuInfo[];
  exceededMaximumQuantityItemsSkuAndSkuNames: IExceededMaximumQuantitySkuInfo[];
  shortSupplyItemsSkuAndSkuNames: IExceededMaximumQuantitySkuInfo[];
  orderMinimum: IPriceModel;
  orderMinimumToTotalDifference?: IPriceModel;
  outOfStockSkus: IOutOfStockSkuReorderInfo[];
  outOfStockItemsSkuAndSkuNames: IOutOfStockSkuInfo[];
  freeShippingThreshold?: IPriceModel;
  freeShippingThresholdToTotalDifference?: IPriceModel;
}

const cartInitialState: ICartState = {
  info: {
    totalItemsCounter: 0,
    totalQuantityCounter: 0,
    subTotal: DEFAULT_PRICE_MODEL,
    isPresentDeactivatedItem: false,
    cartCounter: 0,
    addedItemsCounter: 0,
    cancelledSkuIds: [],
    deactivatedSkus: [],
    originalToReplacementSkusMap: {},
    deactivatedItemSkuAndSkuNames: [],
    specialOrderSkus: [],
    unavailableProprietarySkus: [],
    exceededMaxQuantitySkus: [],
    shortSupplyItems: [],
    exceededMaximumQuantityItemsSkuAndSkuNames: [],
    shortSupplyItemsSkuAndSkuNames: [],
    lineItemPriceUpdates: [],
    outOfStockSkus: [],
    outOfStockItemsSkuAndSkuNames: [],
    orderMinimum: DEFAULT_PRICE_MODEL,
  },
  coupon: {
    isPromotionalCodeValid: false,
    discountDetails: {
      totalDiscountedAmount: DEFAULT_PRICE_MODEL,
      originalCartSubTotal: DEFAULT_PRICE_MODEL,
      discountError: '',
      discountErrorFromValidation: '',
      discounts: [],
    },
  },
  originalToReplacementSkusMap: {},
  reorderOriginalToReplacementSkusMap: {},
  deactivatedItemsSkus: [],
  lineItems: [],
  loading: false,
  isProductBeingUpdated: false,
};

export const shoppingCartInitialState: IShoppingCartState = {
  cartInfo: cartInitialState,
  productRecommendations: [],
};

const updateLineItemsState = (state: ICartState, payload: ICartPayload) => {
  const { lineItems, discountDetails, ...rest } = payload;
  return {
    ...state,
    info: rest,
    lineItems,
    coupon: {
      discountDetails: !discountDetails
        ? {
            totalDiscountedAmount: DEFAULT_PRICE_MODEL,
            originalCartSubTotal: DEFAULT_PRICE_MODEL,
            discountError: '',
            discountErrorFromValidation: '',
            discounts: [],
          }
        : discountDetails,
      isPromotionalCodeValid: discountDetails?.discounts?.length > 0,
    },
    loading: false,
    isProductBeingUpdated: false,
  };
};

const cartInfo = createReducer<ICartState>(
  {
    [actions.getCartInfoActionConstants[Actions.SUCCESS]]: (
      state,
      { payload: { lineItems, originalToReplacementSkusMap, discountDetails, ...rest } }
    ) => ({
      ...state,
      info: { ...rest, originalToReplacementSkusMap },
      coupon: {
        isPromotionalCodeValid: discountDetails?.discounts?.length > 0,
        discountDetails: !discountDetails
          ? {
              totalDiscountedAmount: DEFAULT_PRICE_MODEL,
              originalCartSubTotal: DEFAULT_PRICE_MODEL,
              discountError: '',
              discountErrorFromValidation: '',
              discounts: [],
            }
          : discountDetails,
      },
      lineItems,
      originalToReplacementSkusMap: !isEmpty(originalToReplacementSkusMap)
        ? originalToReplacementSkusMap
        : state.originalToReplacementSkusMap,
      loading: false,
    }),
    [actions.changeCartItemQuantityActionConstants[Actions.REQUEST]]: (state: ICartState) => ({
      ...state,
      isProductBeingUpdated: true,
    }),
    [actions.changeCartItemQuantityActionConstants[Actions.SUCCESS]]: (
      state: ICartState,
      { payload }: IAction<ICartPayload>
    ) => updateLineItemsState(state, payload),
    [actions.changeCartItemQuantityActionConstants[Actions.FAIL]]: (state: ICartState) => ({
      ...state,
      isProductBeingUpdated: false,
    }),
    [actions.setCartItemQuantityActionConstants[Actions.REQUEST]]: (state: ICartState) => ({
      ...state,
      isProductBeingUpdated: true,
    }),
    [actions.setCartItemQuantityActionConstants[Actions.SUCCESS]]: (
      state: ICartState,
      { payload }: IAction<ICartPayload>
    ) => updateLineItemsState(state, payload),
    [actions.setCartItemQuantityActionConstants[Actions.FAIL]]: (state: ICartState) => ({
      ...state,
      isProductBeingUpdated: false,
    }),
    [actions.deleteCartItemActionConstants[Actions.REQUEST]]: (state: ICartState) => ({
      ...state,
      isProductBeingUpdated: true,
    }),
    [actions.deleteCartItemActionConstants[Actions.SUCCESS]]: (state: ICartState, { payload }: IAction<ICartPayload>) =>
      updateLineItemsState(state, payload),
    [actions.deleteCartItemActionConstants[Actions.FAIL]]: (state: ICartState) => ({
      ...state,
      isProductBeingUpdated: true,
    }),
    [actions.deleteAllCartItemsActionConstants[Actions.REQUEST]]: (state: ICartState) => ({
      ...state,
      isProductBeingUpdated: true,
    }),
    [actions.deleteAllCartItemsActionConstants[Actions.SUCCESS]]: (
      state: ICartState,
      { payload }: IAction<ICartPayload>
    ) => updateLineItemsState(state, payload),
    [actions.deleteAllCartItemsActionConstants[Actions.FAIL]]: (state: ICartState) => ({
      ...state,
      isProductBeingUpdated: true,
    }),
    [actions.getCartInfoActionConstants[Actions.REQUEST]]: (state) => ({ ...state, loading: true }),
    [actions.getCartInfoActionConstants[Actions.FAIL]]: (state) => ({ ...state, loading: false }),
    [actions.addMultipleProductsToCartActionConstants[Actions.SUCCESS]]: (
      state: ICartState,
      { payload }: IAction<IReorderPayload>
    ) => ({
      ...state,
      info: {
        ...state.info,
        ...payload,
      },
      originalToReplacementSkusMap: !isEmpty(payload.originalToReplacementSkusMap)
        ? payload.originalToReplacementSkusMap
        : state.originalToReplacementSkusMap,
      reorderOriginalToReplacementSkusMap: payload.originalToReplacementSkusMap,
      loading: false,
      isProductBeingUpdated: false,
    }),
    [shoppingListsActions.addShoppingListToCartActionConstants[Actions.SUCCESS]]: (
      state: ICartState,
      { payload }: IAction<IReorderPayload>
    ) => ({
      ...state,
      info: {
        ...state.info,
        ...payload,
      },
      reorderOriginalToReplacementSkusMap: payload.originalToReplacementSkusMap,
      deactivatedItemsSkus: payload.deactivatedSkus,
      loading: false,
    }),
    [actions.reorderProductsActionConstants[Actions.SUCCESS]]: (
      state: ICartState,
      { payload }: IAction<IReorderPayload>
    ) => ({
      ...state,
      info: {
        ...state.info,
        ...payload,
      },
      reorderOriginalToReplacementSkusMap: payload.originalToReplacementSkusMap,
      loading: false,
    }),
    [actions.reorderProductsActionConstants[Actions.FAIL]]: (state) => ({ ...state, loading: false }),
    [checkoutActions.submitOrderActionConstants[Actions.SUCCESS]]: (state) => ({
      ...state,
      info: {
        ...state.info,
        cartCounter: 0,
      },
    }),
    [actions.addProductToCartActionConstants[Actions.REQUEST]]: (state: ICartState) => ({
      ...state,
      isProductBeingUpdated: true,
    }),
    [actions.addProductToCartActionConstants[Actions.SUCCESS]]: (
      state: ICartState,
      { payload: { lineItems, discountDetails, originalToReplacementSkusMap, ...rest } }
    ) => ({
      ...state,
      info: { ...rest, originalToReplacementSkusMap },
      lineItems,
      coupon: {
        discountDetails: !discountDetails
          ? {
              totalDiscountedAmount: DEFAULT_PRICE_MODEL,
              originalCartSubTotal: DEFAULT_PRICE_MODEL,
              discountError: '',
              discountErrorFromValidation: '',
              discounts: [],
            }
          : discountDetails,
        isPromotionalCodeValid: discountDetails?.discounts?.length > 0,
      },
      originalToReplacementSkusMap: !isEmpty(originalToReplacementSkusMap)
        ? originalToReplacementSkusMap
        : state.originalToReplacementSkusMap,
      loading: false,
      isProductBeingUpdated: false,
    }),
    [actions.addProductToCartActionConstants[Actions.FAIL]]: (state: ICartState) => ({
      ...state,
      isProductBeingUpdated: false,
    }),
    [actions.addMultipleProductsToCartActionConstants[Actions.REQUEST]]: (state: ICartState) => ({
      ...state,
      isProductBeingUpdated: true,
    }),
    [actions.addMultipleProductsToCartActionConstants[Actions.FAIL]]: (state: ICartState) => ({
      ...state,
      isProductBeingUpdated: false,
    }),
    [actions.getPromotionCouponCodeConstants[Actions.SUCCESS]]: (
      state: ICartState,
      { payload }: IAction<ICartPayload>
    ) => ({
      ...state,
      info: {
        ...state.info,
        subTotal: payload.subTotal,
        orderMinimum: payload.orderMinimum,
        orderMinimumToTotalDifference: payload.orderMinimumToTotalDifference,
        freeShippingThreshold: payload.freeShippingThreshold,
        freeShippingThresholdToTotalDifference: payload.freeShippingThresholdToTotalDifference,
      },
      coupon: {
        ...state.coupon,
        discountDetails: payload.discountDetails,
      },
      lineItems: payload.lineItems,
    }),
    [types.SET_IS_PROMOTIONAL_CODE_VALID]: (state: ICartState, { payload }) => ({
      ...state,
      coupon: {
        ...state.coupon,
        isPromotionalCodeValid: payload.isPromotionalCodeValid,
      },
    }),
    [actions.getPromotionCouponCodeConstants[Actions.FAIL]]: (state: ICartState, { error }) => ({
      ...state,
      coupon: {
        ...state.coupon,
        discountDetails: {
          ...cartInitialState.coupon.discountDetails,
          discountError: error.errorMessageId,
          discountErrorFromValidation: error.errorMessageId,
        },
      },
    }),
    [actions.deletePromotionCouponCodeActionConstants[Actions.REQUEST]]: (state: ICartState) => ({
      ...state,
      isProductBeingUpdated: false,
    }),
    [actions.deletePromotionCouponCodeActionConstants[Actions.SUCCESS]]: (
      state: ICartState,
      { payload }: IAction<ICartPayload>
    ) => updateLineItemsState(state, payload),
    [actions.deletePromotionCouponCodeActionConstants[Actions.FAIL]]: (state: ICartState) => ({
      ...state,
      isProductBeingUpdated: false,
    }),
    [types.SET_REORDER_ORIGINAL_TO_REPLACEMENT_SKU_MAP]: (state: ICartState, { payload }) => ({
      ...state,
      reorderOriginalToReplacementSkusMap: payload,
    }),
    [types.CLEAR_ORIGINAL_TO_REPLACEMENT_SKU_MAP]: (state: ICartState) => ({
      ...state,
      originalToReplacementSkusMap: {},
      reorderOriginalToReplacementSkusMap: {},
    }),
    [types.RESET_PROMOTIONAL]: (state: ICartState) => ({
      ...state,
      coupon: {
        isPromotionalCodeValid: false,
        discountDetails: {
          totalDiscountedAmount: DEFAULT_PRICE_MODEL,
          originalCartSubTotal: DEFAULT_PRICE_MODEL,
          discountError: '',
          discountErrorFromValidation: '',
          discounts: [],
        },
      },
    }),
    [impersonationStartActionConstants[Actions.SUCCESS]]: (state: ICartState) => ({
      ...state,
      coupon: {
        isPromotionalCodeValid: false,
        discountDetails: {
          totalDiscountedAmount: DEFAULT_PRICE_MODEL,
          originalCartSubTotal: DEFAULT_PRICE_MODEL,
          discountError: '',
          discountErrorFromValidation: '',
          discounts: [],
        },
      },
    }),
  },
  cartInitialState
);

const productRecommendations = createReducer<ICarouselProduct[]>(
  {
    [actions.getProductRecommendationsActionConstants[Actions.SUCCESS]]: (state, { payload }) => [...payload],
  },
  []
);

export default combineReducers({
  cartInfo,
  productRecommendations,
});
